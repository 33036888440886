






















import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { searchController } from './search.controller'

@Observer
@Component
export default class extends Vue {
  searchController = searchController
}
